import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { toast } from "react-toastify";
import {
    RefreshCcw,
	CheckCircle,
	XCircle,
	Copy
} from 'react-feather';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader, Button, Form, FormGroup, Input, Label,
		Popover, PopoverBody } from "reactstrap";
import { getAllLegalForms, getAllLegalFormUsers, generateRandomBytes, addLegalForm, approveLegalForm, deleteLegalForm } from '../../actions';
import logo from "../../assets/images/dashboard/logo.png";

const ListLegalForm = () => {
	const statusList = ["New", "Pending", "Approved", "Inactived"];
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [link, setLink] = useState("");
	const [email, setEmail] = useState("");
	const [showEmail, setShowEmail] = useState(true);
	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState(0);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

    const togglePopover = () => setPopoverOpen(!popoverOpen);

	const onOpenModal = () => {
		generateRandomBytes(24).then(randomBytes => {
			setLink(randomBytes);
			setOpen(true);
		}).catch(error => {
			console.error('Error generating random bytes:', error);
		});
	};

	const onCloseModal = () => {
		setLink("");
		setSelectedUser(0);
		setOpen(false);
	};

	const onSelectUser = (e) => {
		if(e.target.value === "0") {
			setShowEmail(true);
		} else {
			setShowEmail(false);
		}
		setSelectedUser(e.target.value);
	};

	const refreshLink = () => {
		generateRandomBytes(24).then(randomBytes => {
			setLink(randomBytes);
		}).catch(error => {
			console.error('Error generating random bytes:', error);
		});
	};

	const copyLink = () => {
		navigator.clipboard.writeText("https://set-up-form.crelevator.com/" + link).then(function() {
			togglePopover();
		});
	};

	const handleEmail = (e) => {
		setEmail(e.target.value);
	};

	const copyPDFLink = (pdfLink) => {
		navigator.clipboard.writeText(pdfLink).then(function() {
			toast.success("Successfully Copied!");
		});
	};

	const downloadPDF = async (pdfLink) => {
		const response = await fetch(pdfLink);
        if (response.ok) {
            const blob = await response.blob();
			const link = document.createElement('a');
			const url = window.URL.createObjectURL(blob);
			link.href = url;
			link.download = pdfLink.split("/").pop();
			document.body.appendChild(link);
			link.click();
			link.remove();
			window.URL.revokeObjectURL(url);
        }
	};

	const viewPDF = (pdfLink) => {
		if (pdfLink === "") {
			toast.error('PDF link is not available');
			return;
		}

		window.open(pdfLink, '_blank');
	};

	const approveForm = (formId, formLink, formStatus) => {
		const dataT = {
			id: formId,
			link: formLink,
			status: formStatus
		};
		setIsLoading(true);
		approveLegalForm(dataT).then(res => {
			if(res.success) {
				let temp = [];
				res.forms.forEach((form) => {
					temp.push({
						company: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_legal_name,
						name: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_contact_name,
						email: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_email,
						status: statusList[form.completed],
						options : <div style={{ display: "flex", justifyContent: "center" }}>
							<i
								className={`fa fa-clone ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Copy Link"
								onClick={() => copyPDFLink(form.pdf_link)}
							></i>
							<i
								className={`fa fa-download ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Download PDF"
								onClick={() => downloadPDF(form.pdf_link)}
							></i>
							<i
								className={`fa fa-eye ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="View PDF"
								onClick={() => viewPDF(form.pdf_link)}
							></i>
							<i
								className={`fa fa-check ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Approve"
								onClick={() => approveForm(form.id, form.link, form.completed)}
							></i>
							<i
								className={`fa fa-trash-o`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Delete"
								onClick={() => deleteForm(form.id)}
							></i>
						</div>
					});
				});
				setData(temp);
				if(formStatus === 1 || formStatus === 3) {
					toast.success('Approved the form successfully.');
				} else {
					toast.success('Deactivate the form successfully.');
				}
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		});
	};

	const deleteForm = (formId) => {
		const dataT = {
			id: formId,
		};
		setIsLoading(true);
		deleteLegalForm(dataT).then(res => {
			if(res.success) {
				let temp = [];
				res.forms.forEach((form) => {
					temp.push({
						company: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_legal_name,
						name: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_contact_name,
						email: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_email,
						status: statusList[form.completed],
						options : <div style={{ display: "flex", justifyContent: "center" }}>
							<i
								className={`fa fa-clone ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Copy Link"
								onClick={() => copyPDFLink(form.pdf_link)}
							></i>
							<i
								className={`fa fa-download ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Download PDF"
								onClick={() => downloadPDF(form.pdf_link)}
							></i>
							<i
								className={`fa fa-eye ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="View PDF"
								onClick={() => viewPDF(form.pdf_link)}
							></i>
							<i
								className={`fa fa-check ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Approve"
								onClick={() => approveForm(form.id, form.link, form.completed)}
							></i>
							<i
								className={`fa fa-trash-o`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Delete"
								onClick={() => deleteForm(form.id)}
							></i>
						</div>
					});
				});
				setData(temp);
				toast.success('Deleted the form successfully.');
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		});
	};

	const onSave = () => {
		if(parseInt(selectedUser) === 0) {
			if(email === "") {
				toast.warning("Please type the email for customer.");
				return;
			}
		}

		const dataT = {
			user_id: selectedUser,
			customer_email: email,
			link: link,
		};
		addLegalForm(dataT).then(res => {
			if(res.success) {
				let temp = [...data];
				temp.push({
					company: "",
					name: "",
					email: "",
					status: statusList[0],
					options : <div style={{ display: "flex", justifyContent: "center" }}>
						<i
							className={`fa fa-clone disabled`}
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
							title="Copy Link"
						></i>
						<i
							className={`fa fa-download disabled`}
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
							title="Download PDF"
						></i>
						<i
							className={`fa fa-eye disabled`}
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
							title="View PDF"
						></i>
						<i
							className={`fa fa-check disabled`}
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
							title="Approve"
						></i>
						<i
							className={`fa fa-trash-o`}
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
							title="Delete"
							onClick={() => deleteForm(res.id)}
						></i>
					</div>
				});
				setData(temp);
				const updatedUserList = userList.filter(user => user.id !== parseInt(selectedUser));
				setUserList(updatedUserList);

				toast.success("Successfully Added!");
				onCloseModal();
			} else {
				toast.error(res.message);
				generateRandomBytes(24).then(randomBytes => {
					setLink(randomBytes);
				}).catch(error => {
					console.error('Error generating random bytes:', error);
				});
			}
		});
	};

	useEffect(() => {
		getAllLegalForms().then(res => {
			if(res.success) {
				let temp = [];
				res.forms.forEach((form, index) => {
					temp.push({
						company: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_contact_name,
						name: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_contact_name,
						email: form.LegalFormDatum === null ? "" : form.LegalFormDatum.company_email,
						status: statusList[form.completed],
						options : <div style={{ display: "flex", justifyContent: "center" }}>
							<i
								className={`fa fa-clone ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Copy Link"
								onClick={() => copyPDFLink(form.pdf_link)}
							></i>
							<i
								className={`fa fa-download ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Download PDF"
								onClick={() => downloadPDF(form.pdf_link)}
							></i>
							<i
								className={`fa fa-eye ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="View PDF"
								onClick={() => viewPDF(form.pdf_link)}
							></i>
							<i
								className={`fa fa-check ${form.completed < 1 ? 'disabled' : ''}`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Approve"
								onClick={() => approveForm(form.id, form.link, form.completed)}
							></i>
							<i
								className={`fa fa-trash-o`}
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
								title="Delete"
								onClick={() => deleteForm(form.id)}
							></i>
						</div>
					});
				});
				setData(temp);
			}
		});
		getAllLegalFormUsers().then(res => {
			if(res.success) {
				setUserList(res.users);
			}
		});
	}, []);

	return (
		<>
			{
				isLoading ?
					<div className="loader-wrapper">
						<div className="loader-ring">
							<img src={logo} alt="logo" />
						<span className="loader-span"></span>
						</div>
					</div>
				:
					<Fragment>
						<Breadcrumb title="Legal Form List" parent="Customers" />
						<Container fluid={true}>
							<Card>
								<CardBody>
									<div className="btn-popup pull-right">
										<Button
											type="button"
											color="secondary"
											onClick={onOpenModal}
											data-toggle="modal"
											data-original-title="test"
											data-target="#exampleModal"
										>
											Generate New Link
										</Button>
										<Modal isOpen={open} toggle={onCloseModal}>
											<ModalHeader toggle={onCloseModal}>
												<h5
													className="modal-title f-w-600"
													id="exampleModalLabel2"
												>
													Generate New Link
												</h5>
											</ModalHeader>
											<ModalBody>
												<Form>
													<FormGroup>
														<Label className="col-form-label">
															<span>*</span> Customer List
														</Label>
														<select className="form-select" required=""
															onChange={onSelectUser}
															value={selectedUser}
														>
															<option value={0}>--Select--</option>
															{
																userList.map((user, index) => (
																	<option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
																))
															}
														</select>
													</FormGroup>
													{
														showEmail &&
														<FormGroup>
															<Label
																htmlFor="recipient-name"
																className="col-form-label"
															>
																Customer Email :
															</Label>
															<Input
																type="text"
																className="form-control"
																value={email}
																onChange={handleEmail}
															/>
														</FormGroup>
													}
													<FormGroup>
														<Label
															htmlFor="recipient-name"
															className="col-form-label"
														>
															Link :
														</Label>
														<div style={{display: "flex"}}>
															<Input type="text" className="form-control" value={link} disabled />
															<Button
																type="button"
																color="secondary"
																style={{marginLeft: "0.5rem", padding: "0 0.25rem"}}
																onClick={copyLink}
																id="copyButton"
															>
																<Copy />
															</Button>
															<Button
																type="button"
																color="secondary"
																style={{marginLeft: "0.5rem", padding: "0 0.25rem"}}
																onClick={refreshLink}
															>
																<RefreshCcw />
															</Button>
															<Popover
																flip
																placement="top"
																target="copyButton"
																trigger="legacy"
																toggle={togglePopover}
																isOpen={popoverOpen}
															>
																<PopoverBody>
																	Copied!
																</PopoverBody>
															</Popover>
														</div>
													</FormGroup>
												</Form>
											</ModalBody>
											<ModalFooter>
												<Button
													type="button"
													color="primary"
													onClick={onSave}
												>
													Save
												</Button>
												<Button
													type="button"
													color="secondary"
													onClick={onCloseModal}
												>
													Close
												</Button>
											</ModalFooter>
										</Modal>
									</div>
									<div className="clearfix"></div>
									<div
										id="batchDelete"
										className="category-table user-list order-table coupon-list-delete"
									>
										<Datatable
											multiSelectOption={false}
											multiSelectOptionShow={false}
											myData={data}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
										/>
									</div>
								</CardBody>
							</Card>
						</Container>
					</Fragment>
			}
		</>
	);
};

export default ListLegalForm;
